module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Руслан Хуснетдинов","short_name":"Руслан Хуснетдинов","description":"Я продакт-менеджер с 8-летним опытом в разработке. Моя ключевая экспертиза в создании и разработке технически сложных продуктов и управлении кросс-функциональными командами. Умею быстро и недорого тестировать гипотезы, исследовать пользовательский опыт и анализировать данные.","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"/home/runner/work/ruslanxdev.github.io/ruslanxdev.github.io/src/images/icon-128.png","icons":[{"src":"icons/icon-128.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-196.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-196.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-196.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-196.png","sizes":"192x192","type":"image/png"},{"src":"icons/icon-512.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-512.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1943b312fb60a28a39211b3ce5e7d092"},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":97534752,"webvisor":true,"trackHash":true,"afterBody":false,"defer":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
